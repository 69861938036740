<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col-1">
                            <a @click="$router.push({name :'menuJovenes', params: {id: joven.id}})"
                                class="btn btn-info"
                                ><i class="fas fa-arrow-alt-circle-left"></i
                            ></a>
                        </div>
                        <div class="col-4">
                            <div class="btn_group">
                                <router-link
                                    :to="{
                                        name: 'addExpedientes',
                                        params: { id: joven.id },
                                    }"
                                    id="agregar"
                                    class="btn btn-info"
                                    >Agregar <i class="fas fa-plus"></i
                                ></router-link>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h3 class="title">Expediente Educativo de <span>{{ this.joven.nombre }}</span>.</h3>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <table
                        class="table table-bordered table-striped table-condensed"
                    >
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Instituto Actual</th>
                                <th>Instituto Anterior</th>
                                <th>Nota I Periodo</th>
                                <th>Nota II Periodo</th>
                                <th>Nota III Periodo</th>
                                <th>Boleta Matrícula</th>
                                <th>Notas</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="expediente in datos"
                                :key="expediente.id"
                            >
                                <td>{{ expediente.fecha }}</td>
                                <td>{{ expediente.institutoActual }}</td>
                                <td>{{ expediente.institutoAnterior }}</td>
                                <td>{{ expediente.primer_periodo || '' }}</td>
                                <td>{{ expediente.segundo_periodo || '' }}</td>
                                <td>{{ expediente.tercer_periodo }}</td>
                                <td>
                                    <a v-if="expediente.boletaMatricula"
                                        target="_blank"
                                        class="pdf"
                                        :href="`${filebaseurl}${expediente.boletaMatricula}`"
                                        alt=""
                                        >Boleta de Matrícula</a
                                    >
                                </td>
                                <td>
                                    <a v-if="expediente.notas"
                                        target="_blank"
                                        class="pdf"
                                        :href="`${filebaseurl}${expediente.notas}`"
                                    >
                                        Notas</a
                                    >
                                </td>
                                <td>
                                    <div class="btn-group" role="group">
                                        <router-link
                                            :to="{
                                                name: 'verExpediente',
                                                params: { id: expediente.id },
                                            }"
                                            class="btn btn-primary"
                                            ><i class="fa-solid fa-eye"></i>
                                        </router-link>
                                        <router-link
                                            :to="{
                                                name: 'editExpedientes',
                                                params: { id: expediente.id },
                                            }"
                                            class="btn btn-success"
                                            ><i class="fas fa-edit"></i
                                        ></router-link>
                                        <button
                                            class="btn btn-danger"
                                            @click="
                                                deleteExpediente(expediente.id)
                                            "
                                        >
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Pagination
                        v-if="listLength > 10"
                        :listLength="listLength"
                        :paginaActual="paginaActual"
                        :getNextPage="getNextPage"
                        :totalPaginas="totalPaginas"
                        :getPreviousPage="getPreviousPage"
                        :isActive="isActive"
                        :getDataPagina="getDataPagina"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NoExist from "../../Shared/NoExist.vue";
import Pagination from "../../Shared/Pagination.vue";
export default {
    inject: ["reload"],
    components: {
        NoExist,
        Pagination,
    },
    data() {
        return {
            filebaseurl:process.env.VUE_APP_DOCUMENTS_BASE_URL,
            expedientes: [],
            joven: [],
            pageSize: 10,
            datos: [],
            paginaActual: 1,
            listLength: 0,
        };
    },
    created() {
        this.axios
            .get(`/api/expediente-educativo/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then((response) => {
                this.expedientes = response.data.expedientes;
                this.joven = response.data.joven;
                this.listLength = this.expedientes.length;
                this.getDataPagina(1);
            });
    },
    methods: {
        deleteExpediente(id) {
            this.axios.delete(`/api/expediente-educativo/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
                let i = this.expedientes.map((data) => data.id).indexOf(id);
                this.expedientes.splice(i, 1);
                this.reload();
            });
            Swal.fire({
                title: "Eliminada!",
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
        },
        totalPaginas() {
            return Math.ceil(this.expedientes.length / this.pageSize);
        },
        getDataPagina(noPagina) {
            this.paginaActual = noPagina;
            this.datos = [];
            let x = noPagina - 1;
            let ini = x * this.pageSize;
            let fin = noPagina * this.pageSize;
            this.datos = this.expedientes.slice(ini, fin);
        },
        getPreviousPage() {
            if (this.paginaActual > 1) {
                this.paginaActual--;
            }
            this.getDataPagina(this.paginaActual);
        },
        getNextPage() {
            if (this.paginaActual < this.totalPaginas()) {
                this.paginaActual++;
            }
            this.getDataPagina(this.paginaActual);
        },
        isActive(noPagina) {
            return noPagina == this.paginaActual ? "active" : "";
        },
    },
};
</script>
