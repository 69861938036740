<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col-1">
                            <a @click="$router.push({name :'menuJovenes', params: {id: joven.id}})"
                                class="btn btn-info"
                                ><i class="fas fa-arrow-alt-circle-left"></i
                            ></a>
                        </div>
                        <div class="col-4">
                            <div class="btn_group">
                                <router-link
                                    :to="{
                                        name: 'addEvoluciones',
                                        params: { id: joven.id },
                                    }"
                                    id="agregar"
                                    class="btn btn-info"
                                    >Agregar <i class="fas fa-plus"></i
                                ></router-link>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h3 class="title">Evolución Medica de <span>{{ this.joven.nombre }}</span>.</h3>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <table class="table table-bordered table-striped table-condensed">
                        <thead>
                            <tr>
                            <th>Fecha</th>
                            <th>Diagnóstico</th>
                            <th>Especialista</th>
                            <th>Documento</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="evolucion in datos" :key="evolucion.id">
                        <td>{{ evolucion.fecha }}</td>
                        <td>{{ evolucion.diagnostico }}</td>
                        <td>{{ evolucion.especialista}}</td>
                        <td><a v-if="evolucion.archivo" :href="`${filebaseurl}/jovenes/evaluaciones/${evolucion.archivo}`" target="_blank">Ver</a></td>
                        <td>
                        <div class="btn-group" role="group">
                          <router-link
                            :to="{ name: 'editEvoluciones', params: { id: evolucion.id } }"
                            class="btn btn-success"
                            ><i class="fas fa-edit"></i></router-link>
                            <button
                            class="btn btn-danger"
                            @click="deleteEvolucion(evolucion.id)"
                            >
                            <i class="fas fa-trash-alt"></i>
                            </button>
                        </div>
                        </td>
                            </tr>
                    </tbody>
                    </table>
                    <Pagination
                        v-if="listLength > 10"
                        :listLength="listLength"
                        :paginaActual="paginaActual"
                        :getNextPage="getNextPage"
                        :totalPaginas="totalPaginas"
                        :getPreviousPage="getPreviousPage"
                        :isActive="isActive"
                        :getDataPagina="getDataPagina"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "../../Shared/Pagination.vue";
export default {
   inject:['reload'],
   components: {
        Pagination,
    },
  data() {
    return {
      filebaseurl: process.env.VUE_APP_DOCUMENTS_BASE_URL,
      evoluciones: [],
      joven:[],
      pageSize: 8,
      datos:[],
      paginaActual:1,
      listLength: 0,
    };
  },
  created() {
      this.axios
        .get(`/api/evoluciones/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        .then((response) => {
        this.evoluciones = response.data.evoluciones;
        this.joven = response.data.joven;
        this.listLength = this.evoluciones.length;
        this.getDataPagina(1);
    });
  },
   methods: {
    deleteEvolucion(id) {
      this.axios
        .delete(`/api/evoluciones/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        .then((response) => {
          let i = this.evoluciones.map((data) => data.id).indexOf(id);
          this.evoluciones.splice(i, 1);
          this.reload();
        });
        Swal.fire({
            title: 'Eliminada!',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
        })
    },
    totalPaginas(){
        return Math.ceil(this.evoluciones.length / this.pageSize)

     },
     getDataPagina(noPagina){

            this.paginaActual = noPagina;
            this.datos=[];
            let x = noPagina-1;
            let ini = x * this.pageSize;
            let fin = noPagina * this.pageSize;
            this.datos = this.evoluciones.slice(ini,fin);


     },
     getPreviousPage(){
       if(this.paginaActual>1){
         this.paginaActual--;
       }
       this.getDataPagina(this.paginaActual);
     },
     getNextPage(){
       if(this.paginaActual<this.totalPaginas()){
         this.paginaActual++;
       }
       this.getDataPagina(this.paginaActual);
     },
     isActive(noPagina){
       return noPagina == this.paginaActual ? 'active':'';
     }
  },
};
</script>