<template>
    <div class="container">
        <div class="row">
            <div class="col-12 mt-4">
                <a
                    @click="$router.push({ path: '/jovenes' })"
                    class="btn btn-info pull-right"
                    id="volver"
                    ><i class="fas fa-arrow-alt-circle-left"></i
                ></a>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-12 col-xl-12 mx-auto">
                <div class="row">
                    <div class="col-12 text-center">
                        <img
                            class="imagenF"
                            width="100"
                            height="100"
                            :src="`${filebaseurl}/jovenes/${joven.foto}`"
                            alt=""
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <h3 class="title text-center" style="display: inline">
                            {{ joven.nombre }}
                        </h3>
                    </div>
                </div>
                <div class="row flex-row d-flex flex-wrap justify-content-center">
                    <router-link
                        :to="{
                            name: 'expedientes',
                            params: { id: joven.id },
                        }"
                        class="btn btnJovenes"
                        id="jovenes"
                        ><i class="fas fa-book"></i> Expediente Educativo</router-link>
                    <router-link
                        :to="{ name: 'titulos', params: { id: joven.id } }"
                        class="btn btnJovenes"
                        id="jovenes"
                        ><i class="fas fa-graduation-cap"></i> Títulos</router-link>
                    <router-link
                        :to="{ name: 'citas', params: { id: joven.id } }"
                        class="btn btnJovenes"
                        id="jovenes"
                        ><i class="fas fa-book-medical"></i> Citas</router-link>
                    <router-link
                        :to="{ name: 'recetas', params: { id: joven.id } }"
                        class="btn btnJovenes"
                        id="jovenes"
                        ><i class="fas fa-clipboard-list"></i> Recetas </router-link>
                    <router-link
                        :to="{
                            name: 'evoluciones',
                            params: { id: joven.id },
                        }"
                        class="btn btnJovenes"
                        id="jovenes"
                        ><i class="fas fa-file-medical"></i> Evolución Médica </router-link>
                    <router-link
                        :to="{
                            name: 'nutriciones',
                            params: { id: joven.id },
                        }"
                        class="btn btnJovenes"
                        id="jovenes"
                        ><i class="fas fa-weight"></i> Estado Nutricional</router-link>
                    <router-link
                        :to="{ name: 'vacunas', params: { id: joven.id } }"
                        class="btn btnJovenes"
                        id="jovenes"
                        ><i class="fas fa-syringe"></i> Vacunas </router-link>
                    <router-link
                        :to="{
                            name: 'personales',
                            params: { id: joven.id },
                        }"
                        class="btn btnJovenes"
                        id="jovenes"
                        ><i class="fas fa-user-friends"></i> Personal Acompañante</router-link>
                    <router-link
                        :to="{
                            name: 'laboratorios',
                            params: { id: joven.id },
                        }"
                        class="btn btnJovenes"
                        id="jovenes"
                        ><i class="fas fa-vials"></i> Laboratorios </router-link>
                    <router-link
                        :to="{
                            name: 'epicrisis',
                            params: { id: joven.id },
                        }"
                        class="btn btnJovenes"
                        id="jovenes"
                        ><i class="fas fa-folder-plus"></i> Epicrisis</router-link>
                    <router-link
                        :to="{ name: 'periodos', params: { id: joven.id } }"
                        class="btn btnJovenes"
                        id="jovenes"
                        ><i class="fas fa-female"></i> Periodo Menstrual</router-link>
                    <router-link
                        :to="{
                            name: 'padecimientos',
                            params: { id: joven.id },
                        }"
                        class="btn btnJovenes"
                        id="jovenes"
                        ><i class="fas fa-thermometer-three-quarters"></i> Padecimientos
                    </router-link>
                    <router-link
                        :to="{
                            name: 'dolencias',
                            params: { id: joven.id },
                        }"
                        class="btn btnJovenes"
                        id="jovenes"
                        ><i class="fas fa-procedures"></i> Dolencias</router-link>
                    <router-link
                        :to="{ name: 'cirugias', params: { id: joven.id } }"
                        class="btn btnJovenes"
                        id="jovenes"
                        ><i class="fas fa-stethoscope"></i> Cirugías</router-link>
                    <router-link
                        :to="{
                            name: 'pertenencias',
                            params: { id: joven.id },
                        }"
                        class="btn btnJovenes"
                        id="jovenes"
                        ><i class="fas fa-shirt"></i> Pertenencias</router-link>
                    <router-link
                        :to="{ name: 'llamadas', params: { id: joven.id } }"
                        class="btn btnJovenes"
                        id="jovenes"
                        ><i class="fa-solid fa-phone"></i> Llamadas
                    </router-link>
                    <router-link
                        :to="{ name: 'eventosByJoven', params: { jovenId: joven.id } }"
                        class="btn btnJovenes"
                        id="jovenes"
                        ><i class="fa-solid fa-calendar"></i> Eventos
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    inject: ["reload"],
    data() {
        return {
            joven: {},
            filebaseurl: process.env.VUE_APP_DOCUMENTS_BASE_URL,
        };
    },
    created() {
        this.axios
            .get(`/api/jovenes/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then((response) => {
                this.joven = response.data.joven;
            });
    },
};
</script>
