<template>
  <div class="container">
    <div class="row px-3 m-auto">
      <div class="col-lg-12 col-xl-12 card">
        <div class="row px-3 ">
          <div class="col-sm-12 col-md-6 col-xl-6 d-flex justify-content-center align-items-center">
            <img class="logo" :src="`${filebaseurl}/logos_empresas/${logoPath}`" alt="logo app" />
          </div>
          <div class="col-sm-12 col-md-6 col-xl-6">
            <h3 class="title text-center mt-4">Iniciar Sesión</h3>
            <form @submit.prevent="login" action class="form-box px-3">
              <span><i class="fas fa-envelope"></i></span>
              <label class="form-label" for="#email">Correo electrónico:</label>
              <input v-model="email" class="form-control" type="email" id="email" required />
              <span><i class="fa fa-key"></i></span>
              <label class="form-label" for="#password">Password:</label>
              <input v-model="password" class="form-control" type="password" id="password" required />
              <div v-if="errorMessage" class="error-message">
                {{ errorMessage }}
              </div>
              <div class="mb-3 text-sm-end">
                <button id='ingresar' type="submit" class="btn btn-block text-uppercase"
                  @click.prevent="login">Ingresar</button>
                  <a id='olvidar' class="btn btn-link text-decoration-none" href="#">Forgot password?</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      filebaseurl: process.env.VUE_APP_DOCUMENTS_BASE_URL,
      email: '',
      password: '',
      errorMessage: ''
    };
  },
  computed: {
    ...mapState(['logoPath']), // Mapea 'logoPath' como una propiedad computada
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch('login', {
          email: this.email,
          password: this.password
        });
        this.$router.push({ name: 'home' }); // Redirigir al usuario a la página de inicio después de iniciar sesión
      } catch (error) {
        console.error('Login failed:', error);
        this.errorMessage = 'Las credenciales no coinciden con un usuario.'

      }
    }
  }
};
</script>

<style scoped>

.container {
  margin-top: 25vh;
}
.form-box {
  svg {
    margin-right: 4px;
    color: var(--color-primario);
  }
}
.error-message {
  color: --var(color-secundario);
  font-size: 0.9em;
  margin-top: 10px;
}

label {
    color: var(--color-primario);
}
h3 {
    color: var(--color-primario);
}

#olvidar {
    color: var(--color-secundario) !important;
}

#ingresar {
    background-color: var(--color-primario);
    border-color: var(--color-primario);
    width: 200px;
    margin-top: 20px;
    align-items: center;
    color: white;
}
#ingresar:hover {
    background-color: var(--color-secundario);
}
</style>