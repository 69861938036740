<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <a
                        @click="$router.go(-1)"
                        class="btn btn-info pull-right"
                        id="volver"
                        ><i class="fas fa-arrow-alt-circle-left"></i
                    ></a>
                    <h3 class="title text-center mt-4">Expediente Educativo</h3>
                    <form @submit.prevent="updateExpediente">
                        <div class="form-group">
                            <div class="form-group">
                                <label>Fecha</label>
                                <input
                                    type="date"
                                    class="form-control"
                                    v-model="expediente.fecha"
                                />
                            </div>
                            <label>Instituto Actual</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="expediente.institutoActual"
                            />
                        </div>
                        <div class="form-group">
                            <label>Instituto Anterior</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="expediente.institutoAnterior"
                            />
                        </div>
                        <div class="row justify-content-center">
                            <div class="form-group1">
                                <label>Nota I Periodo</label>
                                <input
                                    type="number"
                                    min="0"
                                    max="100"
                                    class="form-control"
                                    v-model="expediente.primer_periodo"
                                />
                            </div>
                            <div class="form-group1">
                                <label>Nota II Periodo</label>
                                <input
                                    type="number"
                                    min="0"
                                    max="100"
                                    class="form-control"
                                    v-model="expediente.segundo_periodo"
                                />
                            </div>
                            <div class="form-group1">
                                <label>Nota III Periodo</label>
                                <input
                                    type="number"
                                    min="0"
                                    max="100"
                                    class="form-control"
                                    v-model="expediente.tercer_periodo"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Boleta de Matrícula</label>
                            <div class="row">
                                <div class="col-6">
                                    <input
                                        type="file"
                                        class="form-control"
                                        @change="obtenerDocumento"
                                    />
                                </div>
                                <div class="col-6">
                                    <a v-if="expediente.boletaMatricula"
                                        target="_blank"
                                        class="pdf"
                                        :href="`${filebaseurl}${expediente.boletaMatricula}`"
                                        alt=""
                                        >Ver Boleta de Matrícula</a
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Notas</label>
                            <div class="row">
                                <div class="col-6">
                                    <input
                                        type="file"
                                        class="form-control"
                                        @change="obtenerDocumento2"
                                    />
                                </div>
                                <div class="col-6">
                                    <a v-if="expediente.notas"
                                        target="_blank"
                                        class="pdf"
                                        :href="`${filebaseurl}${expediente.notas}`"
                                    >
                                       Ver Notas</a
                                    >
                                </div>
                            </div>


                        </div>
                        <button type="submit" class="btn btn-success">Editar</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            filebaseurl:process.env.VUE_APP_DOCUMENTS_BASE_URL,
            expediente: {},
            documento: {
                nombre: "",
                stock: 0,
                archivo: "",
            },
            documento2: {
                nombre: "",
                stock: 0,
                archivo: "",
            },
        };
    },
    created() {
        this.axios
            .get(`/api/expediente-educativo/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then((response) => {
                this.expediente = response.data.expediente;
            });
    },
    methods: {
        obtenerDocumento(event) {
            let file = event.target.files[0];
            this.documento.archivo = file;
        },
        obtenerDocumento2(event) {
            let file2 = event.target.files[0];
            this.documento2.archivo = file2;
        },
        updateExpediente() {
            let formData = new FormData();
            formData.append("boletaMatricula", this.documento.archivo);
            formData.append("notas", this.documento2.archivo);
            formData.append("fecha", this.expediente.fecha);
            formData.append("primer_periodo", this.expediente.primer_periodo);
            formData.append("segundo_periodo", this.expediente.segundo_periodo);
            formData.append("tercer_periodo", this.expediente.tercer_periodo);
            formData.append("institutoActual", this.expediente.institutoActual);
            formData.append(
                "institutoAnterior",
                this.expediente.institutoAnterior
            );
            formData.append("_method", "PATCH");
            this.axios
                .post(`/api/expediente-educativo/${this.$route.params.id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    },
                })
                .then((res) => {
                    Swal.fire({
                        icon: "success",
                        title: "Expediente Actualizado!",
                        showConfirmButton: false,
                        timer: 1500,
                    }).then((alertResult) => {
                        this.$router.go();
                    });
                });
        },
    },
};
</script>
