<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <a
                        @click="$router.go(-1)"
                        class="btn btn-info pull-right"
                        id="volver"
                        ><i class="fas fa-arrow-alt-circle-left"></i
                    ></a>
                    <h3 class="title text-center mt-4">Expediente Educativo</h3>
                    <div class="row">
                        <div class="col">
                            <label for="" style="color: black"
                                >Jóven:
                                <span style="font-weight: bold">{{
                                    expediente.nombre
                                }}</span></label
                            >
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label for="" style="color: black"
                                >Instituto anterior:
                                <span style="font-weight: bold">{{
                                    expediente.institutoAnterior
                                }}</span></label
                            >
                        </div>
                        <div class="col">
                            <label for="" style="color: black"
                                >Instituto actual:
                                <span style="font-weight: bold">{{
                                    expediente.institutoActual
                                }}</span></label
                            >
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label for="" style="color: black"
                                >Nota I Periodo:
                                <span style="font-weight: bold">{{
                                    expediente.primer_periodo
                                }}</span></label
                            >
                        </div>
                        <div class="col">
                            <label for="" style="color: black"
                                >Nota II Periodo:
                                <span style="font-weight: bold">{{
                                    expediente.segundo_periodo
                                }}</span></label
                            >
                        </div>
                        <div class="col">
                            <label for="" style="color: black"
                                >Nota III Periodo:
                                <span style="font-weight: bold">{{
                                    expediente.tercer_periodo
                                }}</span></label
                            >
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <a v-if="expediente.boletaMatricula"
                                target="_blank"
                                class="pdf"
                                :href="`${filebaseurl}${expediente.boletaMatricula}`"
                                alt=""
                                >Ver Boleta de Matrícula</a
                            >
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <a v-if="expediente.notas"
                                target="_blank"
                                class="pdf"
                                :href="`${filebaseurl}${expediente.notas}`"
                                alt=""
                                >Ver Notas</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            filebaseurl:process.env.VUE_APP_DOCUMENTS_BASE_URL,
            expediente: {},
            fotosAcademicas: [],
            pageSize: 2,
            datos: [],
            paginaActual: 2,
        };
    },
    created() {
        this.axios
            .get(`/api/expediente-educativo/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then((response) => {
                this.expediente = response.data.expediente;
                this.fotosAcademicas = response.data.fotosAcademicas;
                this.getDataPagina(1);
            });
    },
    methods: {
        totalPaginas() {
            return Math.ceil(this.fotosAcademicas.length / this.pageSize);
        },
        getDataPagina(noPagina) {
            this.paginaActual = noPagina;
            this.datos = [];
            let x = noPagina - 1;
            let ini = x * this.pageSize;
            let fin = noPagina * this.pageSize;
            this.datos = this.fotosAcademicas.slice(ini, fin);
        },
        getPreviousPage() {
            if (this.paginaActual > 1) {
                this.paginaActual--;
            }
            this.getDataPagina(this.paginaActual);
        },
        getNextPage() {
            if (this.paginaActual < this.totalPaginas()) {
                this.paginaActual++;
            }
            this.getDataPagina(this.paginaActual);
        },
        isActive(noPagina) {
            return noPagina == this.paginaActual ? "active" : "";
        },
    },
};
</script>
<style>
#demo {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.green {
    background-color: #6fb936;
}
.thumb {
    margin-bottom: 30px;
}

.page-top {
    margin-top: 85px;
}

img.zoom {
    width: 100%;
    height: 200px;
    border-radius: 5px;
    object-fit: cover;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
}

.transition {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}
.modal-header {
    border-bottom: none;
}
.modal-title {
    color: #000;
}
.modal-footer {
    display: none;
}
</style>
