<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col-1">
                            <a
                                @click="$router.go(-1)"
                                class="btn btn-info pull-right"
                                id="volver"
                                ><i class="fas fa-arrow-alt-circle-left"></i
                            ></a>
                        </div>
                        <div class="col">
                            <h3 class="text-center">Reporte de Expediente Médico</h3>
                        </div>
                    </div>
                    <hr>
                    <div class="row filter-container">
                        <div class="col-sm-3">
                            <label id="reportes">Joven</label>
                            <select id="joven"  class="form-control">
                                <option
                                    id="datos"
                                    v-for="joven in jovenes"
                                    :key="joven.id"
                                    :value="joven.id"
                                    v-text="joven.nombre"
                                ></option>
                            </select>
                        </div>
                        <div class="col-sm-2">
                            <label>Fecha Inicio</label>
                            <input type="date" v-model="fecha_inicio" class="form-control" id="fecha_inicio"
                                pattern="\d{4}-\d{2}-\d{2}" />
                        </div>
                        <div class="col-sm-2">
                            <label>Fecha Fin</label>
                            <input type="date" v-model="fecha_final" class="form-control" pattern="\d{4}-\d{2}-\d{2}"  id="fecha_final" />
                        </div>
                        <div class="col-sm-2">
                            <button class="btn btn-info search" @click="buscar()">
                                Consultar <i class="fas fa-search"></i>
                            </button>
                        </div>
                        <div class="col-sm-3">
                            <button class="btn btn-danger download" @click="generateReport()">
                                Descargar PDF
                                <i class="fa-solid fa-file-pdf"></i>
                            </button>
                        </div>
                    </div>
                    <hr>
                </div>
                <NoExist v-if="listLength == 0" />
                <div class="panel-body table-responsive" v-if="listLength != 0">
                    <table class="table table-bordered table-striped table-condensed">
                        <thead>
                            <tr>
                                <th class="text-center">Categoría</th>
                                <th class="text-center">Nombre</th>
                                <th class="text-center">Descripción</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="reporteL in datos" :key="reporteL.id">
                                <td class="text-center">{{ reporteL.categoria }}</td>
                                <td class="text-center">{{ reporteL.nombre }}</td>
                                <td class="text-center">{{ reporteL.detalle }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <VueHtml2pdf
                        :show-layout="false"
                        :float-layout="true"
                        :enable-download="true"
                        :preview-modal="true"
                        :paginate-elements-by-height="1400"
                        filename="Expediente médico"
                        :pdf-quality="2"
                        :manual-pagination="false"
                        pdf-format="letter"
                        pdf-orientation="landscape"
                        pdf-content-width="800px"
                        ref="html2Pdf"
                    >
                        <section
                            slot="pdf-content"
                            style=" color: black;"
                            class=" align-items-center
                                justify-content-center
                                text-center
                            "
                        >
                            <h2 class="text-center">
                                Expediente médico
                            </h2>
                            <NoExist v-if="listLength == 0" />
                            <div class="panel-body table-responsive" v-if="listLength != 0">
                                <table class=" table table-bordered table-striped table-condensed">
                                    <thead>
                                        <tr>
                                            <th class="text-center">Categoría</th>
                                            <th class="text-center">Nombre</th>
                                            <th class="text-center">Descripción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="reporteL in datos"
                                            :key="reporteL.id"
                                        >
                                            <td class="text-center">{{ reporteL.categoria }}</td>
                                            <td class="text-center">{{ reporteL.nombre }}</td>
                                            <td class="text-center">{{ reporteL.detalle }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </section>
                    </VueHtml2pdf>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
import NoExist from "../Shared/NoExist.vue";
export default {
    inject: ["reload"],
    components: {
        NoExist,
        VueHtml2pdf,
    },
    data() {
        return {
            jovenes: [],
            vacunas: [],
            citas: [],
            dolencias: [],
            laboratorios: [],
            padecimientos: [],
            evoluciones: [],
            cirugias: [],
            recetas: [],
            periodo: [],
            nutriciones: [],
            epicrisis: [],
            datos: {},
            listLength: 0,
            fecha_inicio: new Date().toISOString().split('T')[0],
            fecha_final: new Date().toISOString().split('T')[0],
        };
    },
    created() {
        this.axios.get(`/api/reportes/expediente`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
            this.jovenes = response.data.jovenes;
            // this.vacunas = response.data.vacunas;
            // this.citas = response.data.citas;
            // this.dolencias = response.data.dolencias;
            // this.laboratorios = response.data.laboratorios;
            // this.padecimientos = response.data.padecimientos;
            // this.evoluciones = response.data.evoluciones;
            // this.cirugias = response.data.cirugias;
            // this.recetas = response.data.recetas;
            // this.periodos = response.data.periodos;
            // this.nutriciones = response.data.nutriciones;
            // this.epicrisis = response.data.epicrisis;
            // this.listLength = this.datos.length;
        });
    },
    methods: {
        generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
        buscar() {
            const joven = document.getElementById("joven").value;
            const fecha_inicio = document.getElementById("fecha_inicio").value;
            const fecha_final = document.getElementById("fecha_final").value;
            this.axios.get(`/api/reporte/expediente-educativo/`, {
                    headers: { 'Authorization': `Bearer ${sessionStorage.getItem('token')}` }
                }).then((response) => {
                this.datos = response.data;
                this.listLength = this.datos.length;
            });

        },
    },
};
</script>