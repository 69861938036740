<template>
  <div class="container">
    <div class="row px-3 aqui">
      <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
        <div class="card-body">
          <a @click="$router.go(-1)" class="btn btn-info pull-right" id="volver"><i
              class="fas fa-arrow-alt-circle-left"></i></a>
          <h3 class="title text-center mt-4">Evolución Medica</h3>
          <form @submit.prevent="updateEvolucion">
            <div class="form-group">
              <label>Fecha</label>
              <input type="date" class="form-control" v-model="evolucion.fecha" />
            </div>
            <div class="form-group">
              <label>Diagnóstico</label>
              <input type="text" class="form-control" v-model="evolucion.diagnostico" />
            </div>
            <div class="form-group">
              <label>Especialista</label>
              <input type="text" class="form-control" v-model="evolucion.especialista" />
            </div>
            <div class="form-group">
              <label>Archivo</label>
              <input type="file" class="form-control" @change="obtenerDocumento"/>
              <a v-if="evolucion.archivo" :href="`${filebaseurl}/jovenes/evaluaciones/${evolucion.archivo}`" target="_blank">{{ evolucion.archivo }}</a>
            </div>
            <button type="submit" class="btn btn-success">Guardar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filebaseurl:process.env.VUE_APP_DOCUMENTS_BASE_URL,
      evolucion: {
        archivo: ''
      },
      //File
      documento: {
        nombre: '',
        stock: 0,
        archivo: '',
      }
    };
  },
  created() {
    this.axios
      .get(`/api/evoluciones/${this.$route.params.id}`, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      }
      )
      .then((response) => {
        this.evolucion = response.data.evolucion;
      });
  },
  methods: {
    obtenerDocumento(event) {
      let file = event.target.files[0];
      this.documento.archivo = file;
    },
    updateEvolucion() {
      let formData = new FormData();
      formData.append('diagnostico', this.evolucion.diagnostico);
      formData.append('especialista', this.evolucion.especialista);
      formData.append('fecha', this.evolucion.fecha);
      formData.append('archivo', this.documento.archivo);
      formData.append("_method", "PATCH");
      this.axios
        .post(
          `/api/evoluciones/${this.$route.params.id}`,
          formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
          },
        }
        )
        .then((res) => {
          this.$router.go(0);
        });
      Swal.fire({
        icon: 'success',
        title: 'Evolución Medica Actualizada!',
        showConfirmButton: false,
        timer: 1500
      })
    },

  },
};
</script>