<template>
    <div class="container">
        <div class="row px-3">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <a
                        @click="$router.go(-1)"
                        class="btn btn-info pull-right"
                        id="volver"
                        ><i class="fas fa-arrow-alt-circle-left"></i
                    ></a>
                    <h3 class="title text-center mt-4">Citas</h3>
                    <form @submit.prevent="updateCita">
                        <div class="form-group">
                            <label>Fecha</label>
                            <input
                                type="date"
                                class="form-control"
                                v-model="cita.fecha"
                            />
                        </div>
                        <div class="form-group">
                            <label>Nombre del especialista</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="cita.nombre"
                            />
                        </div>
                        <div class="form-group">
                            <label>Especialista</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="cita.especialista"
                            />
                        </div>
                        <div class="form-group">
                            <label>Detalle</label>
                            <textarea
                                class="form-control"
                                v-model="cita.detalle"
                            ></textarea>
                        </div>
                        <div class="form-group">
                            <label>Anexo</label>
                            <div class="row">
                                <div class="col-6">
                                    <input
                                        type="file"
                                        class="form-control"
                                        @change="obtenerAdjunto"
                                    />
                                </div>
                                <div class="col-6">
                                    <a
                                        v-if="cita.foto"
                                        :href="`${filebaseurl}/jovenes/citas${cita.foto}`"
                                        target="_blank"
                                    >
                                        Ver Adjunto</a>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-success">Guardar</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            filebaseurl:process.env.VUE_APP_DOCUMENTS_BASE_URL,
            cita: {},
            imagen: {
                nombre: "",
                archivo: "",
            },
        };
    },
    created() {
        this.axios
            .get(`/api/citas/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then((response) => {
                this.cita = response.data.cita;
                this.joven = response.data.joven;
            });
    },
    methods: {
        obtenerAdjunto(event) {
            if (event.target.files[0]) {
                let file = event.target.files[0];
                this.imagen.archivo = file;
            }
        },
        updateCita() {
            let formData = new FormData();
            if (this.imagen && this.imagen.archivo) {
                formData.append("foto", this.imagen.archivo);
            }
            formData.append("nombre", this.cita.nombre);
            formData.append("fecha", this.cita.fecha);
            formData.append("especialista", this.cita.especialista);
            formData.append("detalle", this.cita.detalle);
            formData.append("joven", this.joven.id);
            formData.append("_method", "PATCH");
            this.axios
                .post(`/api/citas/${this.$route.params.id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    },
                })
                .then((response) => {
                    if(response && response.status == 200){
                      Swal.fire({
                          icon: "success",
                          title: "Cita Actualizada!",
                          showConfirmButton: false,
                          timer: 1500,
                      }).then((alertResult) => {
                          this.$router.go();
                      });
                    } else {
                      Swal.fire({
                          icon: "error",
                          title: "Hubo un error actualizando la cita!",
                          showConfirmButton: false,
                          timer: 1500,
                      });
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => (this.loading = false));
        },
    },
};
</script>
