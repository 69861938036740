<template>
  <div class="row">
    <div class="col-md-offset-1 col-md-12">
      <div class="panel" id="grande">
        <div class="panel-heading">
          <div class="row">
            <div class="col-3 text-start">
              <div
                v-show="
                  userRole === 'Administrador' ||
                  userRole === 'Nivel1' ||
                  userRole === 'SuperAdministrador'
                "
              >
                <div class="btn_group">
                  <router-link to="/addJoven" class="btn btn-info"
                    >Nuevo <i class="fas fa-plus"></i
                  ></router-link>
                </div>
              </div>
            </div>
            <div class="col-9">
              <h3 class="title">PMEs</h3>
            </div>
          </div>
        </div>
        <NoExist v-if="listLength == 0" />
        <div class="panel-body table-responsive" v-if="listLength != 0">
          <div class="col-12">
            <input
              type="text"
              v-model="busqueda"
              id="busqueda"
              class="form-control"
              placeholder="Filtrar lista"
            />
          </div>
          <table class="table table-bordered table-striped table-condensed">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Fecha Nacimiento</th>
                <th>Identificación</th>
                <th>Foto</th>
                <th>Fecha Ingreso</th>
                <th class="text-center"><i class="fa-solid fa-gear "></i></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="joven in listadoJovenes" :key="joven.id">
                <td>{{ joven.nombre }}</td>
                <td>{{ joven.fecha_Nacimiento }}</td>
                <td>{{ joven.identificacion }}</td>
                <td>
                  <img
                    class="imagenF"
                    width="100"
                    height="100"
                    :src="`${filebaseurl}/jovenes/${joven.foto}`"
                    alt=""
                  />
                </td>
                <td>{{ joven.fecha_Ingreso }}</td>
                <td class="text-center">
                  <div class="btn-group" role="group">
                    <router-link
                      :to="{ name: 'editjoven', params: { id: joven.id } }"
                      class="btn btn-success"
                      ><i class="fas fa-edit"></i
                    ></router-link>
                    <button
                      class="btn btn-danger"
                      @click="deleteJoven(joven.id)"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                    <router-link
                      :to="{ name: 'menuJovenes', params: { id: joven.id } }"
                      class="btn btn-info"
                      id="botonJovenes"
                      ><i class="fas fa-plus"></i
                    ></router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NoExist from "../Shared/NoExist.vue";
export default {
  inject: ["reload"],
  components: {
    NoExist,
  },
  data() {
    return {
      filebaseurl: process.env.VUE_APP_DOCUMENTS_BASE_URL,
      jovenes: [],
      pageSize: 10,
      datos: [],
      paginaActual: 1,
      oficinas: [],
      busqueda: "",
      listLength: 0,
    };
  },
  created() {
    // loading events for specific joven
    this.axios
      .get("/api/jovenes?status=active", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        this.jovenes = response.data.jovenes;
        this.oficinas = response.data.oficinas;
        this.listLength = this.jovenes.length;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  computed: {
    userRole() {
      return this.$store.getters.getUserRole;
    },
    listadoJovenes: function () {
      return this.jovenes.filter((joven) => {
        for (let key in joven) {
              if (
                  joven.hasOwnProperty(key) && joven[key] &&
                  joven[key].toString().toLowerCase().includes(this.busqueda.toLowerCase())
              ) {
                  return true;
              }
          }
          return false;
      });
    },
  },
  methods: {
    searchJoven() {
      this.axios
        .get(`/api/jovenes/search?term=${this.busqueda.toLowerCase()}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.jovenes = response.data.jovenes;
          this.listLength = this.jovenes.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteJoven(id) {
      this.axios
        .delete(`/api/jovenes/${id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          let i = this.jovenes.map((data) => data.id).indexOf(id);
          this.jovenes.splice(i, 1);
          this.reload();
        });
      Swal.fire({
        title: "Eliminado!",
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });
    },
    isActive(noPagina) {
      return noPagina == this.paginaActual ? "active" : "";
    },
    cargarOficina(id) {
      var nombres = "";
      for (var key in this.oficinas) {
        for (var i in this.oficinas[key]) {
          if (this.oficinas[key].id == id) {
            nombres = this.oficinas[key].nombre;
          }
        }
      }
      return nombres;
    },
  },
};
</script>
