<template>
    <div class="container">
        <div class="row px-3">
            <div class="col-lg-12 col-xl-12 card flex-row mx-auto">
                <div class="card-body">
                    <router-link to="/jovenes" class="btn btn-info pull-right" id="volver"><i
                            class="fas fa-arrow-alt-circle-left"></i></router-link>
                    <h3 class="title text-center mt-4">Jóvenes</h3>
                    <form @submit.prevent="addJoven">
                        <p v-if="errors.length">
                            <b>Please correct the following error(s):</b>
                        <ul>
                            <li v-for="error in errors">{{ error }}</li>
                        </ul>
                        </p>
                        <h4 class="text-dark">Personal</h4>
                        <p class="text-muted">Información personal de la PME</p>
                        <div class="form-row">
                            <div class="form-group col-xl-6 col-lg-8 col-sm-12">
                                <label>Nombre Completo</label>
                                <input type="text" class="form-control" v-model="joven.nombre" required />
                            </div>
                            <div class="form-group col-xl-2 col-lg-4 col-sm-12">
                                <label>Fecha de Nacimiento</label>
                                <input type="date" class="form-control" v-model="joven.fecha_Nacimiento" required />
                            </div>
                            <div class="form-group col-xl-2 col-lg-4 col-sm-12">
                                <label>Tipo de Identificacion</label>
                                <select class="form-control" v-model="joven.tipo_identificacion" required>
                                    <option value="nacional">Nacional</option>
                                    <option value="residente">Residente</option>
                                    <option value="residente_temporal">Residente Temporal</option>
                                    <option value="persona_extranjera">Persona Extranjera</option>
                                    <option value="persona_refugiada">Persona Refugiada</option>
                                </select>
                            </div>
                            <div class="form-group col-xl-2 col-lg-4 col-sm-12">
                                <label>Identificacion</label>
                                <input type="text" class="form-control" v-model="joven.identificacion" required />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-lg-4 col-sm-4">
                                <label>Género</label>
                                <select class="form-control" v-model="joven.genero" required>
                                    <option value="masculino">Masculino</option>
                                    <option value="femenino">Femenino</option>
                                </select>
                            </div>
                            <div class="form-group col-lg-4 col-sm-4">
                                <label>Nacionalidad</label>
                                <select class="form-control" v-model="joven.nacionalidad" required>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Venezuela">Venezuela</option>
                                </select>
                            </div>
                            <div class="form-group col-lg-4 col-sm-4">
                                <label>Teléfono (Oficina)</label>
                                <input type="tel" class="form-control" v-model="joven.telefono" required />
                            </div>

                        </div>
                        <div class="form-row">
                            <div class="form-group col-12 col-xl-4 col-lg-4">
                                <label>Condición discapacitante</label>
                                <input type="text" class="form-control" v-model="joven.discapacidad" />
                            </div>
                            <div class="form-group col-12 col-xl-4 col-lg-4">
                                <label>Foto</label>
                                <input type="file" class="form-control" accept="image/png, image/jpeg, image/jpg"
                                    @change="obtenerImagen" :required="isRequired" />
                            </div>
                            <div class="form-group col-12 col-xl-4 col-lg-4 border">
                                <figure style="height: 200px; width: 200px; margin:auto;">
                                    <img v-if="imagenes || joven.foto" class="img-thumbnail"
                                        :src="imagenes || `${filebaseurl}/jovenes/` + joven.foto" width="200"
                                        height="200" alt="Foto" />
                                </figure>
                            </div>

                        </div>

                        <hr />
                        <h4 class="text-dark">Preferencias</h4>
                        <p class="text-muted">Información personal de la PME</p>
                        <div class="form-row">
                            <div class="form-group col-lg-4 col-sm-12">
                                <label>Creencia Religiosa</label>
                                <input type="text" class="form-control" v-model="joven.creencia_Religiosa" />
                            </div>
                            <div class="form-group col-lg-4 col-sm-6">
                                <label>Talla</label>
                                <input type="text" class="form-control" v-model="joven.talla_Ropa" required />
                            </div>
                            <div class="form-group col-lg-4 col-sm-6">
                                <label>Color Favorito</label>
                                <input type="text" class="form-control" v-model="joven.color_Favorito" required />
                            </div>
                        </div>

                        <hr />
                        <h4 class="text-dark">Administrativo</h4>
                        <p class="text-muted">Información administrativa de la PME</p>
                        <div class="form-row">
                            <div class="form-group col-lg-3 col-sm-12">
                                <label>Oficina de Procedencia</label>
                                <select class="form-control" v-model="joven.oficina_Procedencia" required>
                                    <option v-for="oficina in oficinas" :key="oficina.id" :value="oficina.id">
                                        {{ oficina.nombre }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group col-lg-3 col-sm-12">
                                <label>Persona Encargada</label>
                                <input type="text" class="form-control" v-model="joven.persona_Encargada" required />
                            </div>

                            <div class="form-group col-lg-3 col-sm-12">
                                <label>Fecha de Ingreso</label>
                                <input type="date" class="form-control" v-model="joven.fecha_Ingreso" required />
                            </div>
                            <div class="form-group col-lg-3 col-sm-12">
                                <label>Fecha de Egreso</label>
                                <input type="date" class="form-control" v-model="joven.fecha_Egreso" />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-lg-3 col-sm-12">
                                <label>Motivo de Ingreso</label>
                                <select class="form-control" v-model="joven.motivo_de_ingreso" required>
                                    <option value="declaratoria_abandono">Declaratoria de Abandono</option>
                                    <option value="abrigo_temporal">Abrigo Temporal</option>
                                    <option value="deposito_judicial">Depósito Judicial</option>
                                </select>
                            </div>
                            <div class="form-group col-lg-3 col-sm-12">
                                <label>Fecha de Ultima Medida</label>
                                <input type="date" class="form-control" v-model="joven.fecha_ultima_medida" />
                            </div>
                            <div class="form-group col-lg-3 col-sm-12">
                                <label>Fecha de Ultimo Plan</label>
                                <input type="date" class="form-control" v-model="joven.fecha_ultimo_plan" />
                            </div>
                            <div class="form-group col-lg-3 col-sm-12">
                                <label>Fecha de Ultima Reunión</label>
                                <input type="date" class="form-control" v-model="joven.fecha_ultima_reunion" />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-10">
                                <label>Otras Señas</label>
                                <textarea class="form-control" v-model="joven.otras_señas"></textarea>
                            </div>
                            <div class="form-group col">
                                <label>Estado <div class="joven_status" :class="joven.estado"></div></label>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-success">
                            Guardar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    inject: ["reload"],
    data() {
        return {
            filebaseurl: process.env.VUE_APP_DOCUMENTS_BASE_URL,
            errors: [],
            joven: {},
            oficinas: [],
            oficina: {},
            imagen: {
                nombre: "",
                stock: 0,
                archivo: "",
            },
            imagenMini: "",
        };
    },

    created() {
        if (this.$route.params.id) {
            this.axios.get(`/api/jovenes/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((res) => {
                this.joven = res.data.joven;
                this.oficinas = res.data.oficinas;
            });


        } else {
            this.axios.get("/api/oficinas", {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
                this.oficinas = response.data;
            });
        }
    },
    methods: {
        obtenerImagen(event) {
            let file = event.target.files[0];
            this.imagen.archivo = file;
            this.cargarImagen(file);
        },
        cargarImagen(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.imagenMini = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        validateForm: function (e) {
            if (this.joven.nombre && this.joven.identificacion) {
                return true;
            }

            this.errors = [];

            if (!this.joven.nombre) {
                this.errors.push('Nombre es requerido.');
            }
            if (!this.joven.identificacion) {
                this.errors.push('Identificación es requeridad.');
            }

            return false;
        },
        addJoven() {
            if (this.validateForm()) {
                let formData = new FormData();
                formData.append("foto", this.imagen.archivo);
                formData.append("nombre", this.joven.nombre);
                formData.append("fecha_Nacimiento", this.joven.fecha_Nacimiento);
                formData.append("identificacion", this.joven.identificacion);
                formData.append("tipo_identificacion", this.joven.tipo_identificacion);
                formData.append("talla_Ropa", this.joven.talla_Ropa);
                formData.append("telefono", this.joven.telefono);
                formData.append("genero", this.joven.genero);
                formData.append("discapacidad", this.joven.discapacidad);
                formData.append("nacionalidad", this.joven.nacionalidad);
                formData.append("creencia_Religiosa", this.joven.creencia_Religiosa);
                formData.append("persona_Encargada", this.joven.persona_Encargada);
                formData.append("color_Favorito", this.joven.color_Favorito);
                formData.append("fecha_Ingreso", this.joven.fecha_Ingreso);
                formData.append("otras_señas", this.joven.otras_señas);
                formData.append("fecha_Egreso", this.joven.fecha_Egreso ? this.joven.fecha_Egreso : '');
                formData.append("motivo_de_ingreso", this.joven.motivo_de_ingreso);
                formData.append("fecha_ultimo_plan", this.joven.fecha_ultimo_plan ? this.joven.fecha_ultimo_plan : '');
                formData.append("fecha_ultima_medida", this.joven.fecha_ultima_medida ? this.joven.fecha_ultima_medida : '');
                formData.append("fecha_ultima_reunion", this.joven.fecha_ultima_reunion ? this.joven.fecha_ultima_reunion : '');
                formData.append("oficina_Procedencia", this.joven.oficina_Procedencia);

                //if updating
                if (this.$route.params.id) {
                    formData.append("_method", "PATCH");
                    this.axios
                        .post(`/api/jovenes/${this.$route.params.id}`, formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                            },
                        })
                        .then((response) => {
                            if (response.data && response.data.errors) {
                                Swal.fire({
                                    title: "Ingrese todos los datos",
                                    icon: "error",
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                            } else {
                                Swal.fire({
                                    icon: "success",
                                    title: "Joven Actualizado!",
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                                this.reload();
                            }
                        });
                } else {
                    //create
                    this.axios.post("/api/jovenes", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                        }
                    }).then((response) => {
                        if (response.data && response.data.errors) {
                            Swal.fire({
                                title: "Revise los datos requeridos y vuelva a intentar",
                                icon: "error",
                                showConfirmButton: false,
                                timer: 1500,
                            });
                        } else {
                            Swal.fire({
                                icon: "success",
                                title: "Jóven Creado!",
                                showConfirmButton: false,
                                timer: 1500,
                            }).then(() => {
                                this.$router.push({ name: 'jovenes' });
                            });
                        }
                    });
                }
            }
        },
    },
    computed: {
        imagenes() {
            return this.imagenMini;
        },
        isRequired() {
            return this.$route.params.id === undefined;
        }
    },
};
</script>
